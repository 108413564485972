import { config } from '@tamagui/config/v3'
import { darkModePaletteColors, paletteColors } from '../colors'
import { createFont, createTamagui } from 'tamagui'

const notoSansFont = createFont({
  family: 'NotoSans',
  size: {
    1: 12,
    2: 14,
    3: 15,
    4: 16,
    5: 18,
  },
  lineHeight: {
    1: 21,
    2: 24.5,
    4: 28,
  },
  weight: {
    1: '400',
    2: '400',
    4: '400',
  },
  letterSpacing: {
    1: 0.05,
    2: 0.05,
    4: 0.25,
  },
  // (native only) swaps out fonts by face/style
  face: {
    300: { normal: 'NotoSans_300Light', italic: 'NotoSans_300Light_Italic' },
    400: {
      normal: 'NotoSans_400Regular',
      italic: 'NotoSans_400Regular_Italic',
    },
    500: {
      normal: 'NotoSans_500Medium',
      italic: 'NotoSans_500Medium_Italic',
    },
    600: {
      normal: 'NotoSans_600SemiBold',
      italic: 'NotoSans_600SemiBold_Italic',
    },
  },
})

export const tamaguiConfig = createTamagui({
  ...config,
  fonts: {
    heading: notoSansFont,
    body: notoSansFont,
    mono: notoSansFont,
  },
  themes: {
    dark: {
      ...darkModePaletteColors,
      color: darkModePaletteColors['grayscale-800'],
      background: darkModePaletteColors['grayscale-000'],
    },
    light: {
      ...paletteColors,
      color: paletteColors['grayscale-800'],
      background: paletteColors['grayscale-000'],
    },
  },
})

declare module 'tamagui' {
  // @ts-ignore -- this is the "main" typed object, which users re-define from tamagui.
  type TamaguiCustomConfig = typeof tamaguiConfig
}

export default tamaguiConfig
