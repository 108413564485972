import { useRouter } from 'next/router'
import { useEffect, useRef, createContext, useContext, type PropsWithChildren } from 'react'

const PreviousUrlContext = createContext<string | null>(null)

export const PreviousRouteProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const previousUrl = useRef<string | null>(null)

  useEffect(() => {
    const handleRouteChangeStart = () => {
      previousUrl.current = router.asPath
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [router])

  return (
    <PreviousUrlContext.Provider value={previousUrl.current}>
      {children}
    </PreviousUrlContext.Provider>
  )
}

export const usePreviousRoute = () => {
  return useContext(PreviousUrlContext)
}
